import { odata } from '../../conf';
import Actions from '../../odata/actions';

const actions = new Actions('playBook', 'playBooks');

const customActions = {
  async LOAD_playBooksExpanded({ rootGetters }) {
    const data = await odata.o(rootGetters)
      .get('playBooks')
      .query('$expand=orgs');

    return data;
  },
  async DUPLICATE_playBook({ rootGetters }, id) {
    const data = await odata.o(rootGetters)
      .post(`playBooks(${id})/Duplicate`)
      .query();

    return data;
  },
};

export default { ...actions, ...customActions };
